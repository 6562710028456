<template>
  <div class="bac-color">
      <vue-header title="我的"/>
      <router-link :to="{name:'profile'}" class="user-box flex flex-x flex-y">
          <div class="flex" v-if="info">
                <div>
                    <img src="../../../images/user.png" v-if="info.sex==1" />
                    <img src="../../../images/girl.png" v-else />
                </div>
                <div class="user-right">
                    <div>{{info.name||info.nikeName}}</div>
                    <div>{{info.phone}}</div>
                </div>
          </div>
      </router-link>
      
      <div class="my-ul" v-if="commission_user && commission_user.is_physiotherapist">
          <router-link :to="`/profit/${info.id}`" class="my-list flex flex-y" v-if="info">
            <div class="my-title">业绩提成</div>
            <div class="my-price flex-1">{{commission_user.price}}元</div>
            <van-icon name="arrow" />
          </router-link>
      </div>
      <div class="my-ul">
          <router-link :to="`/reportList`" class="my-list flex flex-y">
            <div class="flex-1">健康报告</div>
            <van-icon name="arrow" />
          </router-link>
          <router-link :to="`/record`" class="my-list flex flex-y">
            <div class="flex-1">我的预约</div>
            <van-icon name="arrow" />
          </router-link>
          <router-link :to="`/card`" class="my-list flex flex-y">
            <div class="flex-1">我的会员卡</div>
            <van-icon name="arrow" />
          </router-link>
          <router-link :to="`/myMeal/${info.id}`" class="my-list flex flex-y" v-if="info">
            <div class="flex-1">我的套餐</div>
            <van-icon name="arrow" />
          </router-link>
          <router-link :to="`/assess`" class="my-list flex flex-y">
            <div class="flex-1">我的评价</div>
            <van-icon name="arrow" />
          </router-link>
      </div>
  </div>
</template>
<script>
import vueHeader from '@/components/header';
import { mapActions , mapState } from 'vuex';
import { titleFun } from '@/utils';
export default {
  name: 'my',
  components:{
    vueHeader
  },
  created:function(){
      this.axiosMain();
      this.userIdMain({})
      titleFun('我的');
  },
  computed:{
      ...mapState('my',['info','commission_user']),
  },
  methods:{
      ...mapActions('my',['axiosMain','userMealMain' , 'userIdMain'])
  }
}
</script>

<style lang="scss" scoped>
  @import './my';
</style>